/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            
            <div className="container lg:px-0 py-20">
                <Title>Curs Guàrdia Urbana de Barcelona (2024)</Title>
                <Text>
                    El Curs Online per la Guàrdia Urbana de Barcelona és un curs valorat per 5
                    estrelles entre els alumnes de l'anterior edició.
                    <br />
                    <br />
                    Al curs trobareu tot el temari actualitzat de la passada convocatòria,
                    classificats per assignatures.
                    <br />
                    <br />
                    Temari: vídeos explicatius de cada àmbit, resum del temari, fitxes de treball,
                    exercicis per posar-vos a prova i conceptes claus de cada tema.
                    <br />
                    <br />
                    Cultura general: Fitxes explicatives i test per posar-vos a prova.
                    <br />
                    <br />
                    Actualitat: Totes les notícies setmanals en una fitxa, test per comprovar el
                    vostre nivell i un videoresum de totes les notícies de cada mes.
                    <br />
                    <br />
                    Psicotècnics: Disposareu de vídeos explicatius i exercicis per assolir el
                    coneixement.
                    <br />
                    <br />
                    Premis, esports: Disposareu de material actualitzat per anar al dia amb tots els
                    premis, esports, esdeveniments, etc.
                    <br />
                    <br />
                    Amb el suport de classes en directe on podreu interactuar amb la nostra
                    professora i aclarir dubtes.
                    <br />
                    <br />
                    Cada setmana s'aniran desbloquejant noves assignatures i en cap moment es
                    trauran, tot el material és vostre des del primer moment.
                    <br />
                    <br />
                    Com ja veus és el curs més complet sense dubte.
                    <br />
                    <br />
                    El curs estarà disponible fins al dia de l'examen i començarà a mesura que
                    s'apropi la següent convocatòria.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/nlqPl_m5TZM"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </div>
            
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};*/

import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>Ja pots preparar-te per l'accés a la Guàrdia Urbana de Barcelona.</p>

                <p>
                    El <strong>Curs online</strong> és un curs valorat per{" "}
                    <strong>5 estrelles</strong> entre els alumnes de l'anterior edició.
                </p>

                <p>
                    Al curs trobareu tot el <strong>temari actualitzat</strong> de la passada
                    convocatòria, classificats per assignatures.
                </p>

                <p>
                    <strong>Temari:</strong> vídeos explicatius de cada àmbit, resum del temari,
                    fitxes de treball, exercicis per posar-vos a prova i conceptes claus de cada
                    tema.
                </p>

                <p>
                    <strong>Cultura general:</strong> Fitxes explicatives i test per posar-vos a
                    prova.
                </p>

                <p>
                    <strong>Actualitat:</strong> Totes les notícies setmanals en una fitxa, test per
                    comprovar el vostre nivell i un videoresum de totes les notícies de cada mes.
                </p>

                <p>
                    <strong>Psicotècnics:</strong> Disposareu de vídeos explicatius i exercicis per
                    assolir el coneixement.
                </p>

                <p>
                    <strong>Premis, esports:</strong> Disposareu de material actualitzat per anar al
                    dia amb tots els premis, esports, esdeveniments, etc.
                </p>

                <p>
                    Aviat començaran les classes en directe on podreu interactuar amb la nostra
                    professora i aclarir dubtes.
                </p>

                <p>
                    Cada setmana s'aniran desbloquejant noves assignatures i en cap moment es
                    trauran, tot el material és vostre des del primer moment.
                </p>

                <p>Com ja veus, és el curs més complet sense dubte.</p>

                <p>
                    El curs estarà disponible fins al dia de l'examen i començarà a mesura que
                    s'apropi la següent convocatòria.
                </p>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/nlqPl_m5TZM"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>*/}
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per l'accés a la Guàrdia Urbana de Barcelona amb el nostre curs online de 5 estrelles, amb temari actualitzat, classes en directe i tot el material que necessites per triomfar!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
